import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import { APP_DATE_FORMAT } from '../config';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy hh:mm a');
}

export function fDashedDateTimeSecs(date) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
}

export function fDashedDateTimeMin(date) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm');
}

export function fDateDash(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fToDefault(date) {
  if(!date) return "";
  return moment(date).format(APP_DATE_FORMAT);
}

export function dateRangeCalc(startDate, endDate) {
  const dateFrom = moment(startDate);
  const dateTo = moment(endDate);

  if (moment().isSame(dateTo, 'day') && moment().isSame(dateFrom, 'day')) return 'today';
  if (moment().subtract(1, 'day').isSame(dateTo, 'day') && moment().subtract(1, 'day').isSame(dateFrom, 'day'))
    return 'yesterday';
  if (moment().startOf('isoWeek').isSame(dateFrom, 'day') && moment().endOf('isoWeek').isSame(dateTo, 'day'))
    return 'thisWeek';
  if (
    moment().subtract(1, 'isoWeek').startOf('isoWeek').isSame(dateFrom, 'day') &&
    moment().subtract(1, 'isoWeek').endOf('isoWeek').isSame(dateTo, 'day')
  )
    return 'lastWeek';
  if (moment().startOf('month').isSame(dateFrom, 'day') && moment().endOf('month').isSame(dateTo, 'day'))
    return 'thisMonth';
  if (
    moment().subtract(1, 'month').startOf('month').isSame(dateFrom, 'day') &&
    moment().subtract(1, 'month').endOf('month').isSame(dateTo, 'day')
  )
    return 'lastMonth';
  return 'nada';
}

export function getRangeByName(rangeName) {
  if (rangeName === 'today') return { dateStart: moment().format(APP_DATE_FORMAT), dateEnd: moment().format(APP_DATE_FORMAT) };
  if (rangeName === 'yesterday')
    return {
      dateStart: moment().subtract(1, 'day').format(APP_DATE_FORMAT),
      dateEnd: moment().subtract(1, 'day').format(APP_DATE_FORMAT),
    };
  if (rangeName === 'thisWeek')
    return {
      dateStart: moment().startOf('isoWeek').format(APP_DATE_FORMAT),
      dateEnd: moment().endOf('isoWeek').format(APP_DATE_FORMAT),
    };
  if (rangeName === 'lastWeek')
    return {
      dateStart: moment().subtract(1, 'isoWeek').startOf('isoWeek').format(APP_DATE_FORMAT),
      dateEnd: moment().subtract(1, 'isoWeek').endOf('isoWeek').format(APP_DATE_FORMAT),
    };
  if (rangeName === 'thisMonth')
    return {
      dateStart: moment().startOf('month').format(APP_DATE_FORMAT),
      dateEnd: moment().endOf('month').format(APP_DATE_FORMAT),
    };
  if (rangeName === 'lastMonth')
    return {
      dateStart: moment().subtract(1, 'month').startOf('month').format(APP_DATE_FORMAT),
      dateEnd: moment().subtract(1, 'month').endOf('month').format(APP_DATE_FORMAT),
    };

  return { dateStart: moment().format(APP_DATE_FORMAT), dateEnd: moment().format(APP_DATE_FORMAT) };
}

export function fMonthName(number, isFull = true) {
  if(!number) return "";
  return moment(number, "M").format(isFull ? "MMMM" : "MMM");
}