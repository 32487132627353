import jwtDecode from 'jwt-decode';
import { isValidToken } from './jwt';

export function getCurrentUserType() {
  const accessToken = localStorage.getItem('jwt');

  if (accessToken && isValidToken(accessToken)) {
    const { utype, uallowedagents } = jwtDecode(accessToken);

    let retVal = utype;

    if(retVal === 'admin' && !!uallowedagents){
      retVal = 'subadmin';
    }
    
    return retVal;
  }

  return '';
}

export function isCurrentUserAdmin() {
  const userType = getCurrentUserType();

  return ['admin', 'subadmin'].includes(userType);
}
