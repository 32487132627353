// routes
import {
  PATH_DASHBOARD,
  PATH_AGENT,
  // PATH_TRANSACTIONS,
  PATH_STATEMENTS,
  PATH_SUBUSER,
  PATH_WINLOSE,
  PATH_ADMIN,
  PATH_PLAYERS,
  PATH_DEPOSIT_WITHDRAW,
  PATH_BETS,
  PATH_DRAW_RESULTS,
  // PATH_PUBLIC_API,
} from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [
      // DASHBOARD
      // ----------------------------------------------------------------------
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.root,
        icon: ICONS.dashboard,
        perm: ['admin', 'subadmin'],
      },
      // AGENTS
      // ----------------------------------------------------------------------
      {
        title: 'Agents',
        path: PATH_AGENT.root,
        icon: subMenuIcon('mdi:users-group'),
        perm: ['admin'],
      },
      // ADMINS
      // ----------------------------------------------------------------------
      {
        title: 'Admins',
        path: PATH_ADMIN.root,
        icon: subMenuIcon('ri:admin-fill'),
        perm: ['admin'],
      },
      // PLAYERS
      // ----------------------------------------------------------------------
      {
        title: 'players',
        path: PATH_PLAYERS.root,
        icon: subMenuIcon('ph:game-controller-fill'),
      },

      // DEPOSIT/WITHDRAW
      // ----------------------------------------------------------------------
      {
        title: 'Deposit/Withdraw',
        path: PATH_DEPOSIT_WITHDRAW.root,
        icon: subMenuIcon('grommet-icons:transaction'),
      },
      // STATEMENTS
      // ----------------------------------------------------------------------
      {
        title: 'statements',
        path: PATH_STATEMENTS.root,
        icon: ICONS.invoice,
      },

      // WIN/LOSE
      // ----------------------------------------------------------------------
      {
        title: 'Win/Lose',
        path: PATH_WINLOSE.root,
        icon: ICONS.analytics,
      },

      // BET LIST
      // ----------------------------------------------------------------------
      {
        title: 'Bet List',
        path: PATH_BETS.root,
        icon: subMenuIcon('majesticons:money-hand-line'),
      },

      // BET LIST
      // ----------------------------------------------------------------------
      {
        title: 'Draw Results',
        path: PATH_DRAW_RESULTS.root,
        icon: subMenuIcon('carbon:result'),
      },
    ],
  },
];

export default navConfig;
