import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Button,
  Box,
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Select,
  Input,
  TextField,
  Checkbox,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import conObj from '../../../utils/connection-assistant';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

function isObject(variable) {
  let res = false;
  if (typeof variable === 'object' && variable !== null && !Array.isArray(variable)) {
    res = true;
  }

  return res;
}

export default function LoginForm() {
  const { login } = useAuth();

  const theme = useTheme();

  const dispatch = useDispatch();

  const [qrCode, setQrCode] = useState({
    show: false,
    qrImage: '',
  });

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [showAuthField, setShowAuthField] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loginFields, setLoginFields] = useState({
    username: '',
    password: '',
    userCaptchaInput: ''
  });

  const [authCode, setAuthCode] = useState('');

  const [loginAuthPayload, setLoginAuthPayload] = useState({});

  const [errors, setErrors] = useState({});

  const [authError, setAuthError] = useState({});

  const [showOTPModal, setShowOTPModal] = useState(false);

  const [otp, setOtp] = useState('');

  const [smsAuthPayload, setSmsAuthPayload] = useState(null);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    loadCaptchaEnginge(4, theme.palette.background.default, theme.palette.getContrastText(theme.palette.background.default));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      setIsSubmitting(true);

      const reqPayload = {
        username: loginFields.username,
        password: loginFields.password,
        captchaId: 'test',
        captcha: 'test',
      };

      conObj
        .post('Anonymous/login', reqPayload)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              localStorage.setItem('jwt', res.data);
              try {
                login();
              } catch (error) {
                console.error(error);
              }
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            const { errorCode, errorData, message, title } = e.response.data;
            setErrors({ afterSubmit: { message } });
          } else {
            //
          }
          console.log(`Error: ${e.message}`);
        })
        .then(() => {
          setIsSubmitting(false);
        });
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setLoginFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const isValid = () => {
    let valid = true;
    const { username, password, userCaptchaInput } = loginFields;
    const _error = {};

    if (username.trim()) {
      _error.username = '';
    } else {
      _error.username = 'Username is Required';
      valid = false;
    }

    if (password.trim()) {
      _error.password = '';
    } else {
      _error.password = 'Password is Required';
      valid = false;
    }

    if (userCaptchaInput.trim()) {
      _error.userCaptchaInput = '';
      if (validateCaptcha(loginFields.userCaptchaInput.trim()) === true) {
        _error.userCaptchaInput = '';
      } else {
        _error.userCaptchaInput = 'Captcha did not match, Please try again';
        valid = false;
      }
    } else {
      _error.userCaptchaInput = 'Captcha is Required';
      valid = false;
    }

    setErrors(_error);

    return valid;
  };

  return (
    <>
      <Box component={"form"} onSubmit={(e) => handleSubmit(e)} action="#">
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <TextField
            name="username"
            value={loginFields.username}
            label="Username"
            onChange={(e) => handleChange(e)}
            error={errors?.username}
            helperText={errors?.username ? errors?.username : ''}
            required
          />

          <TextField
            name="password"
            value={loginFields.password}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={errors?.password}
            helperText={errors?.password ? errors?.password : ''}
            onChange={(e) => handleChange(e)}
            required
          />

          <Stack direction={'row'} spacing={1} alignItems="unset">
            <TextField
              name="userCaptchaInput"
              label="Enter Captcha"
              onChange={(e) => handleChange(e)}
              error={errors?.userCaptchaInput}
              helperText={errors?.userCaptchaInput ? errors?.userCaptchaInput : ''}
              sx={{ flexGrow: "1" }}
            />
            <Box
              sx={{
                height: 56,
                border: '1px solid rgb(220, 224, 228)',
                borderRadius: '8px',
                pt: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LoadCanvasTemplateNoReload />
            </Box>
          </Stack>
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton sx={{my: 2}} type='submit' fullWidth size="large" variant="contained" loading={isSubmitting} disabled={isSubmitting}>
          Login
        </LoadingButton>
      </Box>
    </>
  );
}
