// ----------------------------------------------------------------------

import { firstName, fullName, lastName } from './name';
import { age } from './number';
import { phoneNumber } from './phoneNumber';

const mockDates = [
  '04/02/2022 10:10:42 PM',
  '08/19/2022 01:32:31 PM',
  '02/25/2022 03:02:10 AM',
  '01/31/2022 03:48:02 PM',
  '05/21/2022 04:17:30 AM',
  '12/11/2022 03:11:50 AM',
  '12/02/2022 06:14:30 PM',
  '11/19/2022 04:02:37 AM',
  '03/18/2022 10:56:01 PM',
  '12/24/2022 08:15:18 PM',
];

export function randomNumber(number) {
  return Math.floor(Math.random() * number) + 1;
}

export function randomNumberRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomInArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 *
 * @param {Object} criteria
 * @param {Number} count
 * @returns {Array.<Object>}
 */
export function mockDataGenerator(criteria, count) {
  if (Object.keys(criteria).length === 0 || !criteria) return false;

  const newMock = [];

  do {
    const mock = {};
    Object.keys(criteria).forEach((key) => {
      let randomValue = criteria[key];
      if (Array.isArray(criteria[key]) && criteria[key]?.length) {
        randomValue = randomInArray(criteria[key]);
      } else {
        switch (criteria[key]) {
          case 'name':
            randomValue = randomInArray(fullName);
            break;
          case 'firstname':
            randomValue = randomInArray(firstName);
            break;
          case 'lastname':
            randomValue = randomInArray(lastName);
            break;
          case 'date':
            randomValue = randomInArray(mockDates);
            break;
          case 'number':
            randomValue = randomNumber(1000);
            break;
          case 'percent':
            randomValue = randomNumber(100);
            break;
          case 'age':
            randomValue = randomInArray(age);
            break;
          case 'price':
            randomValue = randomNumberRange(1000, 1000 * 100);
            break;
          case 'phonenumber':
            randomValue = randomInArray(phoneNumber);
            break;
          default:
            break;
        }
      }

      mock[key] = randomValue;
    });
    newMock.push(mock);
    count--;
  } while (count > 0);

  return newMock;
}
