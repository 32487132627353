// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_AGENT = '/agent/';
const ROOTS_ADMIN = '/admins/';
const ROOTS_SUBUSER = '/sub-user/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STATEMENTS = '/statements/';
const ROOTS_WINLOSE = '/win-lose/';
const ROOTS_DEPOSIT_WITHDRAW = '/deposit-withdraw/';
const ROOTS_PLAYERS = '/players/';
const ROOTS_BETS = '/bets/';
const ROOTS_DRAW_RESULTS = '/draw-results/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_AGENT = {
  root: ROOTS_AGENT,
  form: path(ROOTS_AGENT, 'form'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  form: path(ROOTS_ADMIN, 'form'),
};

export const PATH_SUBUSER = {
  root: ROOTS_SUBUSER,
  form: path(ROOTS_SUBUSER, 'form'),
};

export const PATH_STATEMENTS = {
  root: ROOTS_STATEMENTS,
  form: path(ROOTS_STATEMENTS, 'form'),
};

export const PATH_WINLOSE = {
  root: ROOTS_WINLOSE,
  form: path(ROOTS_WINLOSE, 'form'),
};

export const PATH_DEPOSIT_WITHDRAW = {
  root: ROOTS_DEPOSIT_WITHDRAW,
};

export const PATH_PLAYERS = {
  root: ROOTS_PLAYERS,
};

export const PATH_BETS = {
  root: ROOTS_BETS,
};

export const PATH_DRAW_RESULTS = {
  root: ROOTS_DRAW_RESULTS,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
