import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { getCurrentUserType } from '../utils/userHelper';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const appRoute = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'dashboard', element: <Dashboard />, perm: ['admin', 'subadmin'] },
      ],
    },
    // Agent Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'agent', element: <ManageAgent />, perm: ['admin'] },
      ],
    },

    // Admin Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'admins', element: <ManageAdmin />, perm: ['admin'] },
      ],
    },

    // Win Lose Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'win-lose', element: <WinLose /> },
      ],
    },

    // Statement Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'statements', element: <Statements /> },
      ],
    },
    // Manage Players
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'players', element: <ManagePlayers /> },
      ],
    },
    // Manage Deposit/Withdraw
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'deposit-withdraw', element: <DepositWithdraw /> },
      ],
    },
    // Bet List
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'bets', element: <BetList /> },
      ],
    },
    // Draw Results
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN()} replace />, index: true },
        { path: 'draw-results', element: <DrawResults /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const filteredRoute = appRoute.map((route) => {
    const newRoute = { ...route };

    if (newRoute?.children?.length) {
      const newRouteChildren = [];

      newRoute.children.forEach((childRoute) => {
        if (!childRoute?.perm?.length) newRouteChildren.push(childRoute);
        if (childRoute?.perm?.length && childRoute?.perm?.includes(getCurrentUserType()))
          newRouteChildren.push(childRoute);
      });

      newRoute.children = newRouteChildren;
    }

    return newRoute;
  });

  return useRoutes(filteredRoute);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../views/Home/Dashboard')));

// AGENT
const ManageAgent = Loadable(lazy(() => import('../views/agents/ManageAgents')));

// ADMIN
const ManageAdmin = Loadable(lazy(() => import('../views/admins/ManageAdmins')));

// DEPOSIT/WITHDRAW
const DepositWithdraw = Loadable(lazy(() => import('../views/depositWithdraw/DepositWithdraw')));

// STATEMENTS
const Statements = Loadable(lazy(() => import('../views/statements/Statements')));

// WIN/LOSE
const WinLose = Loadable(lazy(() => import('../views/winlose/WinLose')));

// PLAYERS
const ManagePlayers = Loadable(lazy(() => import('../views/players/ManagePlayers')));

// BET LIST
const BetList = Loadable(lazy(() => import('../views/betList/BetList')));

// DRAW RESULTS
const DrawResults = Loadable(lazy(() => import('../views/drawResults/DrawResults')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
